<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/perguntas-sesmt">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/perguntas-sesmt"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="card">
                        <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                            <AppInfoManual nomeTelaDoManual="perguntas-sesmt-form" />
                            <h3 class="ml-1">{{ title }}</h3>
                        </div>
                        <div class="grid">
                            <div class="field field-checkbox col-12 gap-3">
                                <div class="flex align-items-center gap-2">
                                    <InputSwitch id="ativo" v-model="form.ativo" />
                                    <label for="ativo">Ativo</label>
                                </div>
                            </div>
                            <div class="field field-checkbox col-12" :style="{ display: 'none' }">
                                <label for="isSuits" class="mr-2 ml-0">isSuits</label>
                                <InputSwitch id="isSuits" v-model="form.isSuits" />
                            </div>
                            <div class="field col-6">
                                <label for="descricao">Pergunta</label>
                                <InputText
                                    v-model.trim="form.descricao"
                                    required="true"
                                    autofocus
                                    autocomplete="off"
                                    placeholder="Digite a pergunta"
                                    :class="{ 'p-invalid': submitted && !form.descricao }"
                                />
                                <small class="p-error" v-if="submitted && !form.descricao">Pergunta é obrigatória.</small>
                            </div>
                            <div class="field col-6">
                                <label for="mensagemAjuda">Mensagem do ícone de ajuda</label>
                                <InputText
                                    v-model.trim="form.mensagemAjuda"
                                    required="true"
                                    autofocus
                                    autocomplete="off"
                                    placeholder="Digite a mensagem para auxiliar na interpretação da pergunta"
                                />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-12">
                                <label for="tipo">Tipo de resposta</label>
                                <Dropdown
                                    id="tipo"
                                    class="selects"
                                    v-model="form.tipo"
                                    :options="tipos"
                                    @change="onChangeTipos"
                                    optionValue="value"
                                    optionLabel="label"
                                    placeholder="Selecione..."
                                    :class="{ 'p-invalid': submitted && !form.tipo }"
                                />
                                <small class="p-error" v-if="submitted && !form.tipo">Tipo é obrigatório.</small>
                            </div>

                            <div v-if="componenteOpcao" class="field col-12">
                                <label for="opcoes">Opções</label>
                                <div id="opcoes" class="flex flex-column gap-3">
                                    <div v-for="(opcao, index) in opcoesParaAdicionar" :key="index">
                                        <span class="flex align-items-center gap-3">
                                            <component :is="componenteOpcao" disabled />
                                            <InputText
                                                :id="`opcao-${index + 1}`"
                                                v-model="opcoesParaAdicionar[index]"
                                                class="flex-grow-1"
                                                :class="{ 'p-invalid': submitted && !opcao }"
                                                placeholder="Adicionar opção"
                                                aria-describedby="ajuda-opcao"
                                                @keyup.enter="addOpcao(index)"
                                            />
                                            <Button
                                                v-if="opcoesParaAdicionar.length > 1"
                                                icon="pi pi-times"
                                                class="p-button-text"
                                                severity="danger"
                                                @click="removeOpcao(index)"
                                            />
                                        </span>
                                        <small v-if="submitted && !opcao" class="p-error pl-6">Este campo é obrigatório</small>
                                        <small v-else-if="index === opcoesParaAdicionar.length - 1" id="ajuda-opcao" class="pl-6"
                                            >Pressione enter para adicionar uma nova opção.</small
                                        >
                                    </div>
                                    <div v-if="form.outros">
                                        <span class="flex align-items-center gap-3">
                                            <component :is="componenteOpcao" disabled />
                                            <InputText id="outros" class="flex-grow-1" placeholder="Outros" disabled />
                                        </span>
                                    </div>
                                    <div class="flex align-items-center gap-2">
                                        <InputSwitch id="outros" v-model="form.outros" />
                                        <label for="outros">Incluir opção outros</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
            <ConfirmDialog :closable="false" :draggable="false" :group="form.id" style="width: 600px">
                <template #message="slotProps">
                    <div class="flex flex-column">
                        <ul>
                            <li v-for="(mensagem, index) in slotProps.message.message" :key="index">
                                {{ mensagem }}
                            </li>
                        </ul>
                        <div class="flex flex-row align-items-center gap-3 mt-3">
                            <i class="pi pi-exclamation-triangle text-orange-500 text-4xl text-900"></i>
                            <p class="text-lg font-semibold">
                                As perguntas condicionais das opções alteradas serão removidas, mantendo apenas a pergunta atual nos questionários.
                                Deseja prosseguir?
                            </p>
                        </div>
                    </div>
                </template>
            </ConfirmDialog>
        </template>
    </AppPanel>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import EnumTipoPergunta from '@/enums/EnumTipoPergunta';
import SesmtService from '@/services/SesmtService';
import AppInfoManual from '@/components/AppInfoManual.vue';
import { getClientSesmt } from '../../services/http-sesmt';
import { useConfirm } from 'primevue/useconfirm';

const form = ref({
    ativo: true,
    visivel: true,
    isSuits: false
});
const submitted = ref(false);
const tipos = EnumTipoPergunta;
const service = new SesmtService('/pergunta-sesmt');
const route = useRoute();
const title = computed(() => {
    return route.params.id ? 'Editar pergunta SESMT' : 'Adicionar pergunta SESMT';
});

async function onBeforeSave() {
    submitted.value = true;
}

async function onAfterSave() {
    submitted.value = false;
}

async function onValidate(cbSuccess, cbError) {
    if (form.value.id) {
        const promise = new Promise((resolve, reject) => {
            validarPerguntaSendoUtilizadaEmQuestionarioOuSendoCondicional(resolve, reject);
        });
        try {
            await promise;
        } catch (err) {
            cbError();
        }
    }
    if (form.value?.opcoes) {
        if (form.value.opcoes.filter((opcao, index) => form.value.opcoes.indexOf(opcao) !== index).length >= 1) {
            return cbError();
        }
    }
    if (hasError()) {
        return cbError();
    }
    return cbSuccess();
}

const confirm = useConfirm();

async function validarPerguntaSendoUtilizadaEmQuestionarioOuSendoCondicional(resolve, rejeitar) {
    try {
        const { data } = await getClientSesmt().get(`pergunta-sesmt/validar-vinculos/${form.value.id}`);
        if (!data.possuiVinculo) {
            resolve();
            return;
        }
        confirm.require({
            group: form.value.id,
            header: 'Atualizar pergunta?',
            message: data.mensagens,
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            acceptClass: ' p-button-outlined',
            accept() {
                resolve();
            },
            reject() {
                rejeitar();
            }
        });
    } catch (error) {
        this.$toast.add({
            severity: 'error',
            summary: 'Erro ao atualizar registro! ' + error,
            life: 5000
        });
    }
}

async function onLoadDataEdit(data) {
    form.value = data;
    recuperarOpcoes();
}

function hasError() {
    if (!form.value.descricao) return true;
    if (form.value.tipo === 'ME' || form.value.tipo === 'CB') {
        if (!form.value.opcoes) return true;
        if (form.value.opcoes.filter((opcao) => !opcao).length > 0) return true;
    }
    if (!form.value.tipo) return true;
}

function onChangeTipos(e) {
    if (e.value !== 'ME' && e.value !== 'CB') {
        form.value.opcoes = null;
    }
}

const componenteOpcao = computed(() => {
    if (form.value.tipo === 'ME') {
        return 'Checkbox';
    }
    if (form.value.tipo === 'CB') {
        return 'RadioButton';
    }
    return undefined;
});

const opcoesParaAdicionar = ref(['']);

watch(
    opcoesParaAdicionar,
    (newValue) => {
        form.value.opcoes = newValue;
    },
    { deep: true }
);

function addOpcao(index) {
    if (index === opcoesParaAdicionar.value.length - 1) {
        opcoesParaAdicionar.value.push('');
    }

    nextTick(() => {
        document.getElementById(`opcao-${index + 2}`).focus();
    });
}

function removeOpcao(index) {
    opcoesParaAdicionar.value.splice(index, 1);
}

function recuperarOpcoes() {
    if (form.value.opcoes) {
        opcoesParaAdicionar.value = form.value.opcoes;
    }
}
</script>

<style scoped lang="scss">
.selects {
    border-radius: 6px;
}
</style>
